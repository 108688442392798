import styled, { StyledComponent } from 'styled-components'

export const ContentSeparator: StyledComponent<
  'div',
  any,
  {},
  never
> = styled.div`
  padding-bottom: 4rem;
`
