import styled, { StyledComponent } from 'styled-components'

export const CardHeader: StyledComponent<
  'header',
  any,
  {},
  never
> = styled.header`
  grid-area: 'header';
`
